<template>
  <div
    ref="tilelist"
    class="case-study-tile-list"
  >
    <div
      v-if="!hideCheckbox"
      class="case-study-tile-list__tags"
    >
      <CaseStudyTagFilter
        :selected-tag="selectedTag"
        :filtered-tag-list="filteredTagList"
        :page-tag-list="pageTagList"
        :lang="lang"
        @select-tag="handleTagSelect"
        @reset-tag="handleTagReset"
      />
    </div>

    <transition-group
      class="case-study-tile-list__list"
      name="case-study-tile-list__list"
      tag="ul"
    >
      <li
        v-for="project in currentPageFilteredElements"
        :key="project.id"
      >
        <CaseStudyTile
          class="case-study-tile-list__tile"
          v-bind="project"
          :lang="lang"
        />
      </li>
    </transition-group>

    <Pagination
      :page-count="totalPages"
      :current="currentPage"
      :url="pageSlug"
      :lang="lang"
      pagination-type="virtual"
      @get-next-page="getNextPage"
      @get-prev-page="getPrevPage"
      @get-specific-page="getSpecificPage"
    />

    <div
      v-if="!currentPageFilteredElements || currentPageFilteredElements && !currentPageFilteredElements.length"
      class="case-study-tile-list__empty"
    >
      {{ noCaseStudiesLabel[lang as keyof typeof noCaseStudiesLabel] }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, ref } from 'vue'

import { Pagination } from '~ui/components'
import { usePagination } from '~ui/helpers/pagination'
import type { UiCeCategoryBoxesProps } from '~ui/types'

import CaseStudyTagFilter from '../CaseStudyTileComponents/CaseStudyTagFilter.vue'
import CaseStudyTile from '../CaseStudyTileComponents/CaseStudyTile.vue'

const tilelist = ref<HTMLDivElement | null>(null)

const noCaseStudiesLabel = {
  en: 'We do not have anything related to this (yet!)',
  pl: 'Nie mamy nic z tym związanego (jeszcze!)',
  de: 'Wir haben (noch!) nichts, was damit zusammenhängt'
}

const props = defineProps<UiCeCategoryBoxesProps>()
const pageSlug = computed(() => props.pageData?.slug + '/')

const selectedTag = ref('')

const filteredTagList = computed(() => {
  return [
    ...new Set(props.caseStudyList.flatMap((project) =>
      project?.categories.map(({ category }) => category?.name))
    )
  ]
})

const tagFilteredProjects = computed(() => {
  if (selectedTag.value === '') return props.caseStudyList

  return props.caseStudyList.filter(({ categories }) => {
    const clientCategoryArray = categories.map(({ category }) => category?.name)
    return clientCategoryArray.includes(selectedTag.value)
  })
})
const _pagination = usePagination()

const perPage = 12
const currentPage = ref(1)

const totalPages = computed(() =>
  Math.ceil(tagFilteredProjects.value.length / perPage)
)

const currentPageFilteredElements = computed(() => {
  return _pagination.getItemsForCurrentPage(
    tagFilteredProjects.value,
    currentPage.value,
    perPage
  )
})

const handleTagSelect = (newTag: string) => {
  selectedTag.value = newTag
  currentPage.value = 1
}

const handleTagReset = () => {
  selectedTag.value = ''
}

const getSpecificPage = (pageNumber: number) => {
  currentPage.value = _pagination.getSpecificPage(
    pageNumber,
    currentPage.value,
    totalPages.value
  )

  nextTick(() => {
    tilelist.value?.scrollIntoView({ behavior: 'smooth' })
  })
}

const getNextPage = () => {
  getSpecificPage(_pagination.getNextPage(currentPage.value, totalPages.value))
}

const getPrevPage = () => {
  getSpecificPage(_pagination.getPrevPage(currentPage.value))
}
</script>

<style lang="scss">
.case-study-tile-list {
  margin: 0 0 spacing(xl);
  scroll-margin-top: calc(var(--content-offset) + rem(16px));

  &__tile {
    display: block;
  }

  &__list {
    list-style: none;
    padding: 0;
    padding-bottom: spacing(md);
    margin: spacing(md) auto;
    display: flex;
    flex-direction: column;
    gap: clamp(rem(10px), 1.3vw, rem(24px));

    @include media-query(sm) {
      display: grid;
      grid-template-columns: repeat(2, minmax(rem(348px), 1fr));
      align-items: stretch;
    }
  }

  &__list-enter-active,
  &__list-leave-active {
    transform-origin: top;
    transition:
      opacity 0.25s,
      transform 0.25s;
    transform: scale(1);
    opacity: 1;
  }

  &__list-enter-from,
  &__list-leave-to {
    opacity: 0;
    transform: scale(0.8);
  }

  &__empty {
    color: color(primary);
    font-size: rem(22px);
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: spacing(xl) spacing(md);
    margin-bottom: spacing(xl);
  }
}
</style>
