/**
 * Returns items for current page only from the whole item set
 * @param {array} itemList - array of items to be paginated
 * @param {number} currentPage - current page number
 * @param {number} perPage - number of items per page
 * @returns {array}
 */
const getItemsForCurrentPage = <T>(
  itemList: Array<T>,
  currentPage: number,
  perPage: number
): Array<T> => {
  if (itemList.length > perPage) {
    const sliceStart = (currentPage - 1) * perPage
    return itemList.slice(sliceStart, currentPage * perPage)
  }
  return itemList
}

/**
 * Returns next page number
 * @param {number} currentPage - current page number
 * @param {number} totalPages - total pages number
 * @returns {number} next page number or last page number if current page is already the last one
 */
const getNextPage = (currentPage: number, totalPages: number): number => {
  return currentPage < totalPages ? currentPage + 1 : currentPage
}

/**
 * Returns previous page number
 * @param {number} currentPage - current page number
 * @returns {number} previous page number or first page number if current page is already the first one
 */
const getPrevPage = (currentPage: number): number => {
  return currentPage > 1 ? currentPage - 1 : currentPage
}

/**
 * Returns new current page number to be set based on given number
 * @param {number} pageNumber - number of page to be set
 * @param {number} currentPage - current page number
 * @param {number} totalPages - total pages number
 * @returns {number} the given page number or current page number, if the given one was not valid
 */
const getSpecificPage = (
  pageNumber: number,
  currentPage: number,
  totalPages: number
): number => {
  return pageNumber > 0 && pageNumber <= totalPages ? pageNumber : currentPage
}

export function usePagination() {
  return {
    getItemsForCurrentPage,
    getNextPage,
    getPrevPage,
    getSpecificPage
  }
}
